.react-calendar__month-view__days__day--weekend {
  color: inherit;
}

.react-calendar__tile--now {
  border-radius: 25%;
  background: rgba(245, 158, 11);
}

.react-calendar__tile--now:hover,
.react-calendar__tile--now:focus {
  background: rgb(252, 187, 77);
}

.react-calendar__tile--active {
  border-radius: 25%;
  color: white;
  background: #006edc;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 25%;
}

.react-calendar__tile--hasActive {
  border-radius: 25%;
}

