.fc {
  height: 700px;
}

.fc .fc-scrollgrid table tbody {
  cursor: pointer;
}

.meeting-cal .fc .fc-scrollgrid table tbody {
  cursor: inherit;
}

.meeting-cal .fc .fc-timegrid-col{
  background-color: #cbd5e04b;
}

.fc .fc-timegrid-slot-label {
  background-color: inherit;
}

 .fc .fc-timegrid-col-events {
    cursor: pointer;
}

.fc .fc-timegrid-event:hover {
  opacity: 0.8;
}

.fc .fc-list-event{
  cursor: pointer;
}

.fc .fc-daygrid-event{
  cursor: pointer;
}

/* .fc .fc-timegrid tbody {
  background-color: rgb(245, 245, 245);
}

.fc .fc-scroller {
  overflow-y: hidden;
  background-color: rgb(245, 245, 245);
  width: 100%;
}
 */

@media only screen and (max-width: 767px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
}

/* .fc .fc-bg-event {
  opacity: 0.1;
  cursor: pointer;
   
}

.fc .fc-bg-event:hover {

  cursor: pointer;
  opacity: 1;
}

.fc .fc-bg-event .fc-event-title::inside {
  font-size: 1pt;
  color: black;
  opacity: 1;
  display: block;
}

.fc .fc-bg-event .fc-event-title::first-line {
  color: transparent;
  visibility: hidden;
  font-size: 9pt;
} */

.fc .fc-bg-event:hover .fc-event-title {
  /* z-index: 50; */
  visibility: visible;
  text-decoration: underline overline;
  /* font-weight: bold; */
}